import { CarouselGridWrapper, Carousel, CarouselCol, Badge, useRemoteHandlers, Card, CardBody, CardMedia, CardContent, CardHeadline3, CardFootnote1 } from '@sberdevices/plasma-ui';
import { Row } from '@sberdevices/plasma-ui/components/Grid'
import { isSberBox } from '@sberdevices/plasma-ui/utils'


const IndexPage = ({ status }: any) => {

    const isSberbox = isSberBox()
    const delay = isSberbox ? 300 : 30
    const longDelay = isSberbox ? 1500 : 150
    const [index, setIndex] = useRemoteHandlers({
        initialIndex: 0,
        axis: 'x',
        delay,
        longDelay,
        min: 0,
        max: 9,
    })
    return (
        <CarouselGridWrapper>
            <Carousel
                as={Row}
                axis="x"
                index={index}
                scrollSnapType='mandatory'
                // animatedScrollByIndex
                detectActive={true}
                detectThreshold={0.5}
                style={{ paddingTop: '20px', paddingBottom: '20px' }}
                onIndexChange={i => setIndex(i)}
                scrollAlign="center"
            >
                <CarouselCol scrollSnapAlign="start" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 0} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/nevsknew.jpg' />
                            <CardContent style={{ marginTop: 1,  }}>
                                <CardHeadline3>А. Невского</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.nevskiy} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    02:20 - 05:10
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>

                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 1} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/birzhevoy.jpeg' />
                            <CardContent style={{ marginTop: 1,  }}>
                                <CardHeadline3>Биржевой</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.birzh} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    02:00 - 04:55
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>

                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 2} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/blagove.jpeg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Благовещенский</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.blago} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    02:00 - 04:55,
                                    03:10 - 05:00
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>

                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 3} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/bolsheoho.jpeg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Большеохотинский</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.bolshe} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    02:00 - 05:00
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>

                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 4} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/volodarnew1.jpg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Володарский</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.volodar} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    02:00 - 03:45,
                                    04:15 - 05:45
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>

                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 5} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/dvorco.jpeg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Дворцовый</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.dvorc} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    01:10 - 02:50,
                                    03:10 - 04:55
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>
                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 6} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/liteynew.jpg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Литейный</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.litey} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    01:40 - 04:45
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>
                <CarouselCol scrollSnapAlign="center" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 7} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/troy.jpeg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Троицкий</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.troic} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    01:20 - 04:50
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
        </CarouselCol>
                <CarouselCol scrollSnapAlign="end" sizeS={4} sizeM={3} sizeL={4} sizeXL={4}>
                    <Card focused={index === 8} scaleOnFocus style={{marginLeft: 15}}>
                        <CardBody>
                            <CardMedia src='/tuchkovnew.jpg' />
                            <CardContent style={{ marginTop: 1 }}>
                                <CardHeadline3>Тучков</CardHeadline3><br />
                                <CardFootnote1>
                                    <Badge text={status.tuch} size='l' />
                                </CardFootnote1><br />
                                <CardFootnote1>
                                    График разведения:<br />
                                    02:00 - 02:55, 03:35 - 04:55
                                </CardFootnote1>
                            </CardContent>
                        </CardBody>
                    </Card>
                </CarouselCol>
                </Carousel>
        </CarouselGridWrapper>
    );
}

export default IndexPage