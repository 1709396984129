
import React, { FC, memo, useReducer, useEffect } from 'react';
import { Header, Container } from '@sberdevices/plasma-ui';
import { reducer } from './store';
import { initAssistant } from './assistant';
import IndexPage from './components/IndexPage';

export const App: FC = memo(() => {
    const [appState, dispatch] = useReducer(reducer, {
        currentState: 'index',
        status: {}
    });

    useEffect(() => {
        initAssistant(dispatch);
    }, []);

    const route = () => {
        switch (appState.currentState) {
            case 'index':
                return <IndexPage status={appState.status} />;
            default:
                return <IndexPage status={appState.status} />;
        }
    }

    return (

        <Container>
            <Header
                logo='/bridge.png'
                title='Мосты Санкт-Петербурга'
                subtitle='График разведенных мостов'
            />
            {route()}
        </Container>
    );
}
);

export default App;