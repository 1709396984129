type State = {
    currentState: string,
    status: object
};

type Action =
    | {
    type: 'index',
    status: object
}
    | {
    type: 'SELECT';
};

export const reducer = (state: State, action: Action) => {
    console.log(action);
    switch (action.type) {
        case 'index':
            return {
                ...state,
                status: action.status
            };

        case 'SELECT':
            return {
                ...state
            };


        default:
            return {...state}
    }
};